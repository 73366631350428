export const environment = {
  production: false,
  currency: '',

  // PARAMETROS PuraDelicia GT
  firebase: {
    apiKey: "AIzaSyBIMcd2x3U6oLHKm53KGFuI5SfAWXofmIk",
    authDomain: "ianos-app-gt-puradelicia-pro.firebaseapp.com",
    projectId: "ianos-app-gt-puradelicia-pro",
    storageBucket: "ianos-app-gt-puradelicia-pro.appspot.com",
    messagingSenderId: "130177894419",
    appId: "1:130177894419:web:ba231d900215bf715397e6",
    measurementId: "G-2Y2GMS7TLT"
  },
  googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  urlBase: 'https://us-central1-ianos-app-gt-puradelicia-pro.cloudfunctions.net',
  apikey: 'c4b7937d223-bc9a8f93cc9e0-4745-65824728',

};
